<script>
	export let data;

	let sortedCallSign;
	$: sortedCallSign = [...data].sort((a, b) => {
		return a.callSign[0].localeCompare(b.callSign[0]);
	});

	function calculateHighScore(list, fields, type) {
		let highScore = 0;
		let winner = [];
		let sorted = list.sort((a, b) => b[fields] - a[fields]);
		for(const p of sorted){
			let c = p;
			let f = p;
			for(const field of fields){
				c = f["callSign"];
				f = f[field];
				if(f >= highScore){
					highScore = f;
					winner.push([c[0]]);
				}
			}
		}
		if(highScore == 0){
			if(type == "score") {
				return "";
			} else if(type == "human"){
				return "No contest.";
			} else {
				return "No contest.";
			}
		}
		if(winner.length > 1){
			let winners = winner.toString();
			winners = winners.replace(/,/g, ', ');
			if(type == "score"){
				return highScore;
			} else if(type == "human"){
				return "Tied: " + winners + ".";
			} else {
				return highScore + " - Tied: " + winners + ".";
			}
		} else {
			if(type == "score"){
				return highScore;
			} else if(type == "human") {
				return winner + ".";
			} else {
				return highScore + " - " + winner + ".";
			}
		}
	}

	function calculateHighScoreScans(list, type) {
		let highScore = 0;
		let scanScores = [];
		let winner = [];
		for(const p of list){
			let sum = 0;
			let c = p["callSign"];
			for(const field in p.scans){
				sum += p.scans[field];
			}
			scanScores.push([sum, c[0]]);
		}
		scanScores.sort((a, b) => b[0] - a[0]);
		for(const p of scanScores){
			let hs = p[0];
			let cs = p[1];
			if(hs >= highScore) {
				highScore = hs;
				winner.push([cs]);
			}
		}
		if(highScore == 0){
			if(type == "score") {
				return "";
			} else if(type == "human"){
				return "No contest.";
			} else {
				return "No contest.";
			}
		}
		if(winner.length > 1){
			let winners = winner.toString();
			winners = winners.replace(/,/g, ', ');
			if(type == "score"){
				return highScore;
			} else if(type == "human"){
				return winner + ".";
			} else {
				return highScore + " - Tied: " + winners + ".";
			}			
		} else {
			if(type == "score"){
				return highScore;
			} else if(type == "human"){
				return winner + ".";
			} else {
				return highScore + " - " + winner + ".";
			}
		}
	}
	
	function sumFields(list, fields) {
		let sum = 0;
		for(const p of list){
			let f = p;
			for(const field of fields){
				f = f[field];
			}
			sum += f;
		}
		return sum;
	}

	function sumScans(list) {
		let sum = 0;
		for(const p of list){
			for(const field in p.scans){
				sum += p.scans[field];
			}
		}
		return sum;
	}
</script>

<main>
	<h1 class="center-text title no-margin-bottom">Hurty Sliding</h1>
	<h1 class="center-text title-site no-margin-top">Snow Injuries</h1>
	<div class="flex-container">
		<div class="flex-item">
			<h1 class="center-text title">Tally</h1>
			<h2>Hospital Visits: <span class="random-colour">{sumFields(data, ["hospitalVisits"])}</span></h2>
			<h2>Rescue Toboggans: <span class="random-colour">{sumFields(data, ["rescueToboggans"])}</span></h2>
			<h2>Broken Bones: <span class="random-colour">{sumFields(data, ["brokenBones"])}</span></h2>
			<h2>Damaged Nerves: <span class="random-colour">{sumFields(data, ["damagedNerves"])}</span></h2>
			<h2>Torn Tissue: <span class="random-colour">{sumFields(data, ["tornTissue"])}</span></h2>
			<h2>Scans: <span class="random-colour">{sumScans(data)}</span></h2>
			<ul>
				<li><h3>X-ray: {sumFields(data, ["scans", "xray"])}</h3></li>
				<li><h3>CT-scan: {sumFields(data, ["scans", "ctScan"])}</h3></li>
				<li><h3>MRI: {sumFields(data, ["scans", "mri"])}</h3></li>
				<li><h3>Ultrasound: {sumFields(data, ["scans", "ultrasound"])}</h3></li>
			</ul>
		</div>
		<div class="flex-item">
			<h1 class="center-text title">Victims</h1>
			{#each sortedCallSign as person}
				{#if person.isVictim == true}
					<h2>{person.callSign[0]}</h2>
				{/if}
			{/each}
		</div>
	</div>
	<div class="flex-container">
		<div class="flex-item">
			<h1 class="center-text title">High Scores</h1>
			<h2>Most Rekt:</h2>
			<p class="indent">The Package.</p>
			<h2>Hospital Visits: <span class="random-colour">{calculateHighScore(data, ["hospitalVisits"], "score")}</span></h2>
			<p class="indent">{calculateHighScore(data, ["hospitalVisits"], "human")}</p>
			<h2>Rescue Toboggans: <span class="random-colour">{calculateHighScore(data, ["rescueToboggans"], "score")}</span></h2>
			<p class="indent">{calculateHighScore(data, ["rescueToboggans"], "human")}</p>
			<h2>Broken Bones: <span class="random-colour">{calculateHighScore(data, ["brokenBones"], "score")}</span></h2>
			<p class="indent">{calculateHighScore(data, ["brokenBones"], "human")}</p>
			<h2>Damaged Nerves: <span class="random-colour">{calculateHighScore(data, ["damagedNerves"], "score")}</span></h2>
			<p class="indent">{calculateHighScore(data, ["damagedNerves"], "human")}</p>
			<h2>Torn Tissue: <span class="random-colour">{calculateHighScore(data, ["tornTissue"], "score")}</span></h2>
			<p class="indent">{calculateHighScore(data, ["tornTissue"], "human")}</p>
			<h2>Cumulative Scans: <span class="random-colour">{calculateHighScoreScans(data, "score")}</span></h2>
			<p class="indent">{calculateHighScoreScans(data, "human")}</p>
		</div>
	</div>
	<div class="flex-container">
		<div class="flex-item">
			<h1 class="center-text title">Commendations</h1>
			{#each data as person}
				{#if person.callSign.length > 0 && person.commendations.length > 0}
					<h2>{person.callSign[0]}</h2>
					{#each person.commendations as c}
						<p class="indent">{c}</p>
					{/each}
				{/if}
			{/each}
		</div>
	</div>
	<div class="flex-container">
		<div class="flex-item">
			<h1 class="center-text title">Best Still</h1>
			<img class="best-still" src="/images/beat-you.png" alt="lol">
		</div>
	</div>
	<div class="flex-container">
		<div class="flex-item">
		<h1 class="center-text title">5 Hours</h1>
			<p class="left-border-highlight indent" >Look at my leg, 
			<br>The splint is amazing,
			<br>Crashed on my skis,
			<br>Then walked round for ages, 
			<br>
			<br>With a crack of the bone,
			<br>I gave out a faint moan 
			<br>Weeks later checked it again,
			<br>Turns out it was fractured.
			<br>
			<br>Ew that's nasty,
			<br>Do you think so?</p>
		</div>
	</div>
</main>

<footer>
	<p>The chronicles of Hurty Sliding.</p>
</footer>

<style>
</style>