import App from "./App.svelte";

const data = [
	{
		name: "Jacob",
		callSign: ["Goldilocks"],
		isVictim: true,
		hospitalVisits: 2,
		rescueToboggans: 0,
		brokenBones: 4,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 4,
			ctScan: 1,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Sam",
		callSign: ["The Package"],
		isVictim: true,
		hospitalVisits: 2,
		rescueToboggans: 0,
		brokenBones: 1,
		damagedNerves: 1,
		tornTissue: 0,
		commendations: ["For reliably getting straight munted, literally every time."],
		quotes: [],
		scans: {
			xray: 1,
			ctScan: 0,
			mri: 1,
			ultrasound: 0,
		},
	},
	{
		name: "Verity",
		callSign: ["Tree Hugger", "Bush Bandit"],
		isVictim: true,
		hospitalVisits: 0,
		rescueToboggans: 1,
		brokenBones: 0,
		damagedNerves: 1,
		tornTissue: 1,
		commendations: ["For earning two titles related to flora."],
		quotes: [],
		scans: {
			xray: 1,
			ctScan: 0,
			mri: 1,
			ultrasound: 1,
		},
	},
	{
		name: "Jo",
		callSign: ["Tiramisu"],
		isVictim: true,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: ["For getting food poisoning.", `For birthing "It's time to eat shit!".`],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Nick",
		callSign: ["Missy Smiggins"],
		isVictim: true,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 2,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Ash",
		callSign: ["Papa Bear"],
		isVictim: false,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Ed",
		callSign: ["Chairman Mau"],
		isVictim: false,
		hospitalVisits: 0,
		rescueToboggans: 1,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 1,
		commendations: [],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Cecily",
		callSign: ["Twiggi", "Front Shitter"],
		isVictim: false,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Andrew",
		callSign: ["Buzz"],
		isVictim: false,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
	{
		name: "Aaron",
		callSign: [""],
		isVictim: false,
		hospitalVisits: 0,
		rescueToboggans: 0,
		brokenBones: 0,
		damagedNerves: 0,
		tornTissue: 0,
		commendations: [],
		quotes: [],
		scans: {
			xray: 0,
			ctScan: 0,
			mri: 0,
			ultrasound: 0,
		},
	},
]

const app = new App({
	target: document.body,
	props: {
		data: data
	}
});

export default app;
